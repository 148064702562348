@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

:root {
    --accent: "#f50057";
}

.e-tooltip-wrap {
    border-radius: 8px!important;
}

.custom-scroll::-webkit-scrollbar-track {
    
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* syncfusion Grid alternate row styling */
.e-grid .e-altrow {
    background-color: #fafafa;
}
a {
  color: #0366d6;
}

button:focus{
    outline: none;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

:root {
    --sk-color: #1565c0;
    --primary: #3f51b5;
}

/* hover outline */
#history-grid td.e-rowcell.e-selectionbackground.e-active {
    color: white!important;
    background-color: var(--primary);
}

/* styles for preventing resizing of cells when hover outline is active */
#history-grid tr.e-row:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell):first-child {
    border: thin solid var(--primary) !important;
    border-right: unset !important;
    border-radius: 4px 0px 0px 4px;
    padding: 8px 8px 7px 7px;
}

#history-grid tr.e-row:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell):last-child {
    border: thin solid var(--primary) !important;
    border-left: unset !important;
    border-radius: 0px 4px 4px 0px;
    padding: 8px 7px 7px 8px;
}

#history-grid tr.e-row:hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell):not(:first-child):not(:last-child) {
    border: thin solid var(--primary) !important;
    border-left: unset !important;
    border-right: unset !important;
    padding: 8px 8px 7px 8px;
}

/* special handler for the first row which needs less top-padding */
tr.e-row:hover:first-child .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
   padding-top: 7px!important; /* ensure it overrides the previous styles which are technically more specific */
}

tr.e-row:hover:last-child .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    padding-bottom: 7px !important; /* ensure it overrides the previous styles which are technically more specific */
}

tr.e-row:hover:first-child:last-child .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
    padding-top: 7px!important;
    padding-bottom: 7px !important; /* ensure it overrides the previous styles which are technically more specific */
}

#history-grid .e-gridheader.e-lib.e-droppable {
    padding-right: 0px !important;
}

#history-grid td.e-rowcell {
    padding-left: 8px;
    padding-right: 8px;
}

#history-grid th.e-headercell {
    padding-left: 8px;
    padding-right: 8px;
}

div.e-headercontent {
    border-right: 0px !important;
}

div.e-gridcontent .e-content {
    overflow-y: hidden!important;
}

span.e-headertext {
    font-weight: 800;
}

#share-btn-wrapper {
    position: absolute;
    left: calc(50% - 26px);
    width: 56px;
    height: 30px;
    bottom: -38px;
    /*margin-bottom: -24px;*/
    overflow: hidden;
}

.share-paper {
    width: 48px;
    height: 48px;
    border-radius: 50% !important;
    background-color: white;
    position: absolute; 
    right: auto; 
    left: calc(50% - 24px); 
    bottom: 6px;
}

@media (min-width: 1000px) {
    .share-paper {
        width: 64px;
        height: 64px;
        left: calc(50% - 32px);
        bottom: 8px;
    }

    #share-btn-wrapper {
        left: calc(50% - 37px);
        width: 74px;
        height: 38px;
        bottom: -46px;
    }
}

.map-marker-label {
    margin-bottom: 48px
}

/* used to show the layer text in the dropdown list when selecting run  */
li #layerInSelector {
    display: block;
}

#reportChart_stripline_Behind_collections text:nth-of-type(2n) {
    transform: translate(5px, 20px);
    text-anchor: start;
}

#reportChart_stripline_Behind_collections text:nth-of-type(2n - 1) {
    transform: translate(5px, 0px);
    text-anchor: start;
}

/**
 *  Prevents multi-level labels from being clipped
 */
#reportChart_XAxis_Clippath_0_Rect {
    x: 0;
    width: 100%;
}

/*#outer {
    overflow: hidden;
    padding-bottom: 50px;
    position: absolute;
    right: auto;
    left: calc(50% - 24px);
    bottom: 8px;
}

#outer > div {
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 24px;
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 6px 4px -1px rgba(0, 0, 0, 0.4);
}*/
